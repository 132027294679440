const items = (state) => state.items;
const loading = (state) => state.loading;
const cities = (state) => state.cities;
const shops = (state) => state.shops;
const disabled = (state) => state.disabled;
const pagination = (state) => state.pagination;

export default {
  items,
  cities,
  shops,
  loading,
  disabled,
  pagination
};
