const items = (state) => state.items;
const myPurchases = (state) => state.myPurchases;
const item = (state) => state.item;
const loading = (state) => state.loading;
const disabled = (state) => state.disabled;
const pagination = (state) => state.pagination;

export default {
  items,
  item,
  loading,
  disabled,
  myPurchases,
  pagination
};
