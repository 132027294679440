import Vue from "vue";
import qs from "qs";

export default class RestApi {
  constructor() {
    this.domain = process.env.VUE_APP_API_URL;
  }
  async getData(url, pagination = null) {
    let data = {};
    if (pagination != null) data = pagination;
    const response = await Vue.axios.get(url, {
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      }
    });
    return response.data;
  }

  async insertData(url, data) {
    const response = await Vue.axios.post(url, data);
    return response.data;
  }

  async putData(url, data) {
    const response = await Vue.axios.put(url, data);
    return response.data;
  }
  async patchData(url, data = null) {
    let response = await Vue.axios.patch(url, data);
    return response.data;
  }

  async deleteData(url) {
    let response = await Vue.axios.delete(url);
    return response.data;
  }
}
