<template>
  <form-wizard
    :form-data="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :create-form-data="createFormData"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CampaignCreate",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {
        name: "",
        pictureUrl: "",
        startDate: null,
        endDate: null,
        active: true,
        salesCompaniesIds: [],
        citiesIds: [],
        shopsIds: [],
        category: [],
        subCategoryId: [],
        productIds: [],
        products: []
      },
      createFormData: false
    };
  },
  async created() {
    await this.$store.dispatch("companies/getItems");
    Event.$on("select-field-changed-salesCompaniesIds", (value) => {
      this.$store.commit("companies/setPagination", {
        salesCompaniesIds: value
      });
      this.$store.dispatch("companies/getCities");
    });
    Event.$on("select-field-changed-citiesIds", (value) => {
      this.$store.commit("companies/setPagination", {
        citiesIds: value
      });
      this.$store.dispatch("companies/getShops");
    });

    await this.$store.dispatch("products/getCategories");
    Event.$on("select-field-changed-category", (value) => {
      this.$store.commit("products/setSubcategoriesPagination", {
        categoriesIds: value
      });
      this.$store.dispatch("products/getSubcategories");
    });
    Event.$on("select-field-changed-subCategoryId", (value) => {
      this.$store.commit("products/setProductsPagination", {
        subCategoriesIds: value
      });
      this.$store.dispatch("products/getItems", { pagin: "productsPagination" });
    });
    Event.$on("select-field-changed-productIds", (value) => {
      const options = value.map(function (row) {
        return {
          productId: row.id,
          modelName: row.modelName,
          points: row.points || row.defaultPoints
        };
      });

      this.item.products = options;
    });
  },
  computed: {
    ...mapGetters({
      categories: "products/categories",
      subcategories: "products/subcategories",
      companies: "companies/items",
      cities: "companies/cities",
      shops: "companies/shops",
      products: "products/items"
    }),
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$t("campaigns.title") || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: this.$t("campaigns.form.name") || "Name*",
          value: "",
          placeholder: this.$t("campaigns.form.name") || "Name*",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("campaigns.form.image") || "Image*",
          placeholder: this.$t("campaigns.form.image") || "Image*",
          allowedFormatsText: this.$t("campaigns.form.allowedFormatsText") || "Allowed Formats",
          allowedFormats: this.$t("campaigns.form.allowedFormats") || "jpg, png",
          showPreview: true,
          fileNames: [],
          multiple: false,
          fieldName: "FileUploadField",
          value: "",
          folderUpload: "SalesCampaigns",
          returnString: true,
          name: "pictureUrl",
          class: "col-12 col-sm-12 col-md-12",
          required: true,
          rules: ""
        },
        {
          text: this.$t("campaigns.form.startDate") || "Start date*",
          placeholder: this.$t("campaigns.form.startDate") || "Start date*",
          fieldName: "DateField",
          value: null,
          name: "startDate",
          minDate: "1950-01-01",
          dateFormat: "YYYY-MM-DD",
          fullDate: true,
          class: "col-12 col-sm-12 col-md-4",
          required: true,
          rules: "required"
        },
        {
          text: this.$t("campaigns.form.endDate") || "End date*",
          placeholder: this.$t("campaigns.form.endDate") || "End date*",
          fieldName: "DateField",
          value: null,
          name: "endDate",
          minDate: "1950-01-01",
          dateFormat: "YYYY-MM-DD",
          fullDate: true,
          class: "col-12 col-sm-12 col-md-4",
          required: true,
          rules: ""
        },
        {
          text: this.$t("campaigns.form.active") || "Active",
          placeholder: this.$t("campaigns.form.active") || "Active",
          fieldName: "SwitchField",
          value: null,
          name: "active",
          class: "col-12 col-sm-12 col-md-4",
          required: true,
          rules: ""
        },
        {
          text: this.$t("campaigns.form.company") || "Kompanija*",
          value: [],
          placeholder: this.$t("campaigns.form.company") || "Kompanija*",
          name: "salesCompaniesIds",
          multiple: true,
          items: this.companies,
          disabled: false,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: ""
        },
        {
          text: this.$t("campaigns.form.city") || "Grad*",
          value: [],
          placeholder: this.$t("campaigns.form.city") || "Grad*",
          name: "citiesIds",
          multiple: true,
          items: this.cities,
          disabled: false,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: ""
        },
        {
          text: this.$t("campaigns.form.shops") || "Prodavnice*",
          value: [],
          placeholder: this.$t("campaigns.form.shops") || "Prodavnice*",
          name: "shopsIds",
          multiple: true,
          items: this.shops,
          itemText: "name",
          disabled: false,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: "required"
        },
        {
          text: this.$t("products.form.category") || "Category*",
          value: [],
          placeholder: this.$t("products.form.category") || "Category*",
          name: "category",
          multiple: true,
          items: this.categories,
          disabled: false,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: ""
        },
        {
          text: this.$t("products.form.subcategory") || "Subcategory*",
          value: [],
          placeholder: this.$t("products.form.subcategory") || "Subcategory*",
          name: "subCategoryId",
          multiple: true,
          disabled: false,
          items: this.subcategories,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: ""
        },
        {
          text: this.$t("products.form.products") || "Products",
          value: [],
          placeholder: this.$t("products.form.products") || "Products*",
          name: "productIds",
          multiple: true,
          disabled: false,
          items: this.products,
          itemText: "modelName",
          fieldName: "SelectField",
          required: true,
          returnObject: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: "required"
        },
        {
          text: this.$t("products.form.productPoints") || "Product points*",
          value: "",
          placeholder: this.$t("products.form.productPoints") || "Product points*",
          name: "products",
          loadFrom: "productIds",
          fieldName: "ProductField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        }
      ];
      return form;
    }
  }
};
</script>
