import View from "@/views/admin/Sale.vue";
import Index from "@/components/sales/List.vue";
import Create from "@/components/sales/Create.vue";
import MySalesList from "@/components/sales/MySalesList.vue";
export default [
  {
    path: "sales",
    name: "sales",
    component: View,
    title: "Sales",
    redirect: {
      name: "list-sales"
    },
    children: [
      {
        path: "list",
        name: "list-sales",
        meta: {
          icon: "mdi-sale"
        },
        component: Index
      },
      {
        path: "my-sales",
        name: "my-sales",
        meta: {
          auth: {
            roles: [0, 1],
            rolesKey: "role"
          },
          icon: "mdi-sale"
        },
        component: MySalesList
      },
      {
        path: "create",
        name: "create-sales",
        meta: {
          auth: {
            roles: [0, 1],
            rolesKey: "role"
          },
          storeAction: "sales/insertItem",
          namespace: "sales",
          store: "sales",
          title: "Create sales",
          icon: "mdi-sale"
        },
        props: true,
        component: Create
      }
    ]
  }
];
