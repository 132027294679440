import Vue from "vue";
export default class ErrorService {
  constructor() {
    this.baseBeEndpoint = process.env.VUE_APP_API + "logs/be";
    this.baseFrontEndpoint = process.env.VUE_APP_API + "logs/fe";
    /* 		Vue.config.errorHandler = (err, vm, info) => {
			// err: error trace
			// vm: component in which error occured
			// info: Vue specific error information such as lifecycle hooks, events etc.

			this.logVueErrorToApi({ err, vm, info });
		}; */
  }

  onError({ response }) {
    const {
      data,
      status,
      config: { url, method }
    } = response;
    const message = this.formatMessage(data);
    //this.logToApi({ method, url, status, message });
    this.logToConsole({ method, url, status, message });
  }

  formatMessage(data) {
    if (typeof data === "string") return data;
    if (data.message) return data.message;
  }

  logToApi({ method, url, status, message }) {
    Vue.axios.post(this.baseBeEndpoint, {
      message: `METHOD: ${method}, URL: ${url}, STATUS: ${status}, MESSAGE: ${message}`,
      name: "error"
    });
  }

  logVueErrorToApi({ err, vm, info }) {
    const componentTag = this._getComponentTag(vm);
    Vue.axios.post(this.baseFrontEndpoint, {
      message: `VUE ERROR: ${err}, COMPONENT: ${componentTag}, INFO: ${info}`,
      name: "error"
    });
  }

  logToConsole({ method, url, status, message }) {
    if (process.env.VUE_APP_DEBUG || process.env.NODE_ENV === "development") {
      console.log("API ERROR", method, url, status, message);
    }
  }

  _getComponentTag(vm) {
    if (!vm) {
      return "[unknown]";
    }

    if (vm.$vnode.tag) {
      return vm.$vnode.tag.replace(/vue-component-\d+-/i, "");
    }

    if (vm.$el.id) {
      return vm.$el.id;
    }
  }
}
