import RestApi from "@/api/RestApi";
export default class PurchasesApi extends RestApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "purchase/";
  }

  async getList(pagination) {
    let url = this.baseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async getMyPurchases(pagination) {
    let url = this.baseEndpoint + "personal";
    let response = await this.getData(url, pagination);
    return response;
  }

  async insertItem(id) {
    let url = this.baseEndpoint + id + "/purchase";
    let response = await this.insertData(url);
    return response;
  }
  async approve(id) {
    let url = this.baseEndpoint + id + "/approve";
    let response = await this.patchData(url);
    return response;
  }
  async cancel(id) {
    let url = this.baseEndpoint + id + "/cancel";
    let response = await this.patchData(url);
    return response;
  }
  async deliver(id) {
    let url = this.baseEndpoint + id + "/deliver";
    let response = await this.patchData(url);
    return response;
  }

  async exportToCsv(pagination) {
    let url = this.baseEndpoint + "report";
    let response = await this.getData(url, pagination);
    return response;
  }
}
