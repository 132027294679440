import actions from "@/store/options/actions";
import getters from "@/store/options/getters";
import mutations from "@/store/options/mutations";

export default {
  namespaced: true,
  state: {
    options: {
      loading: false,
      dialog: false,
      snackbar: false,
      color: "red",
      message: "Upss, something goes wrong :( Please refresh page and try again"
    }
  },
  actions,
  getters,
  mutations
};
