import { email, required, numeric, min, alpha_num, confirmed } from "vee-validate/dist/rules";

import { extend, ValidationProvider, setInteractionMode } from "vee-validate";
import { localize } from "vee-validate";
import sr from "vee-validate/dist/locale/sr_Latin.json";
import en from "vee-validate/dist/locale/en.json";
setInteractionMode("aggressive");
const srMessages = sr.messages;
extend("email", { ...email, message: srMessages.email });
extend("required", required);
extend("numeric", { ...numeric, message: srMessages.numeric });
extend("min", { ...min, message: srMessages.min });
extend("confirmed", { ...confirmed, message: srMessages.confirmed });
extend("alpha_num", { ...alpha_num, message: srMessages.alpha_num });
localize({
  en,
  sr
});
localize("sr");
export default {
  components: {
    ValidationProvider
  }
};
