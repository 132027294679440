<template>
  <product-card :item="item" :icon="icon" :namespace="namespace" />
</template>
<script>
export default {
  name: "ProductShow",
  data() {
    return {
      item: {}
    };
  },
  components: { ProductCard: () => import("@/components/core/cards/ProductCard") },
  created() {},
  async mounted() {
    const _this = this;
    await _this.$store.dispatch(_this.store + "/getItem", _this.$route.params.id).then(() => {
      _this.item = _this.$store.getters[_this.store + "/item"];
    });
  },
  computed: {
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$t("product.title") || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    icon() {
      return this.$route.meta.icon || "";
    }
  }
};
</script>
