const items = (state) => state.items;
const latest = (state) => state.latest;
const item = (state) => state.item;
const loading = (state) => state.loading;
const disabled = (state) => state.disabled;
const categories = (state) => state.categories;
const subcategories = (state) => state.subcategories;
const categoriesPagination = (state) => state.categoriesPagination;
const subcategoriesPagination = (state) => state.subcategoriesPagination;
const productsPagination = (state) => state.productsPagination;
const pagination = (state) => state.pagination;

export default {
  items,
  latest,
  item,
  loading,
  disabled,
  categories,
  subcategories,
  categoriesPagination,
  subcategoriesPagination,
  productsPagination,
  pagination
};
