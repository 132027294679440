import Vue from "vue";
import Vuex from "vuex";
import options from "@/store/options/index";
import news from "@/store/news/index";
import products from "@/store/products/index";
import files from "@/store/files/index";
import companies from "@/store/companies/index";
import campaigns from "@/store/campaigns/index";
import rewards from "@/store/rewards/index";
import users from "@/store/users/index";
import purchases from "@/store/purchases/index";
import sales from "@/store/sales/index";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    options,
    news,
    products,
    files,
    companies,
    campaigns,
    rewards,
    users,
    purchases,
    sales
  },
  plugins: []
});
