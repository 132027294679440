import UsersApi from "@/api/users";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new UsersApi();

const getItems = async ({ commit, state }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.getList(state.pagination);
    commit("setItemsData", { value: items, shouldReset: state.pagination.skip === 0 });
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const getLatest = async ({ commit }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.getList({ skip: 0, limit: 50, state: 0 });
    commit("setLatestItemsData", items);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

const getItem = async ({ commit }) => {
  try {
    commit("setLoading", true);
    const item = await restApi.getItem();
    commit("setItemData", item);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const getItemById = async ({ commit }, itemId) => {
  try {
    commit("setLoading", true);
    const item = await restApi.getItemById(itemId);
    commit("setItemData", item);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const updateItem = async ({ commit }, item) => {
  try {
    commit("setLoading", true);
    await restApi.updateItem(item);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const changePassword = async ({ commit }, item) => {
  try {
    commit("setLoading", true);
    const response = await restApi.changePassword(item);
    commit("setLoading", false);
    return response;
  } catch (error) {
    errorHandling.onError(error);
  }
};

const resetPassword = async ({ commit }, { credentials, code }) => {
  try {
    commit("setLoading", true);
    const response = await restApi.resetPassword(credentials, code);
    commit("setLoading", false);
    return response;
  } catch (error) {
    errorHandling.onError(error);
  }
};

const forgotPassword = async ({ commit }, email) => {
  try {
    commit("setLoading", true);
    const response = await restApi.forgotPassword(email);
    commit("setLoading", false);
    return response;
  } catch (error) {
    errorHandling.onError(error);
  }
};

const confirmRegistration = async ({ commit }, id) => {
  try {
    commit("setLoading", true);
    await restApi.confirmRegistration(id);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    commit("setError", true);
    errorHandling.onError(error);
  } finally {
    commit("setLoading", false);
  }
};

const sendConfirmation = async ({ commit }, email) => {
  try {
    commit("setLoading", true);
    await restApi.resendConfirmation(email);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const activate = async ({ commit }, id) => {
  try {
    commit("setLoading", true);
    await restApi.activate(id);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const approve = async ({ commit }, id) => {
  try {
    commit("setLoading", true);
    await restApi.approve(id);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};
const deactivate = async ({ commit }, id) => {
  try {
    commit("setLoading", true);
    await restApi.deactivate(id);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

export default {
  getItems,
  getLatest,
  getItem,
  getItemById,
  setPagination,
  confirmRegistration,
  resetPassword,
  changePassword,
  forgotPassword,
  sendConfirmation,
  updateItem,
  activate,
  approve,
  deactivate
};
