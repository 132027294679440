import View from "@/views/admin/Campaign.vue";
import Index from "@/components/campaigns/List.vue";
import Create from "@/components/campaigns/Create.vue";
import Edit from "@/components/campaigns/Edit.vue";
import Show from "@/components/campaigns/Show.vue";
export default [
  {
    path: "campaigns",
    name: "campaigns",
    component: View,
    title: "Campaigns",
    redirect: {
      name: "list-campaigns"
    },
    children: [
      {
        path: "list",
        name: "list-campaigns",
        meta: {
          auth: {
            roles: [0, 1],
            rolesKey: "role"
          },
          icon: "mdi-cellphone-wireless"
        },
        component: Index
      },
      {
        path: "create",
        name: "create-campaigns",
        meta: {
          storeAction: "campaigns/insertItem",
          namespace: "campaigns",
          store: "campaigns",
          title: "Create campaigns",
          icon: "mdi-cellphone-wireless"
        },
        component: Create
      },
      {
        path: "edit/:id",
        name: "edit-campaigns",
        meta: {
          storeAction: "campaigns/updateItem",
          namespace: "campaigns",
          store: "campaigns",
          title: "Edit campaigns",
          icon: "mdi-cellphone-wireless"
        },
        component: Edit
      },
      {
        path: "show/:id",
        name: "show-campaigns",
        meta: {
          auth: {
            roles: [0, 1],
            rolesKey: "role"
          },
          namespace: "campaigns",
          store: "campaigns",
          icon: "mdi-cellphone-wireless"
        },
        component: Show
      }
    ]
  }
];
