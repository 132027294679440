import RestApi from "@/api/RestApi";
export default class FilesApi extends RestApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "files/";
  }

  async uploadFilePublic(item, folder = "NoSpecifiedFolderUpload") {
    let formData = new FormData();
    formData.append("file", item);
    let url = this.baseEndpoint + folder;
    const response = await this.insertData(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response;
  }
}
