<template>
  <form-wizard
    v-if="item"
    :form-data.sync="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
export default {
  name: "RewardsEdit",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {}
    };
  },
  created() {
    const _this = this;
    _this.$store.dispatch(_this.store + "/getItem", _this.$route.params.id).then(() => {
      _this.item = _this.$store.getters[_this.store + "/item"];

      const { pictureUrl } = _this.item;
      const baseUrlPattern = process.env.VUE_APP_BASE_IMAGE_URL;
      const picturePath = pictureUrl ? pictureUrl.replace(baseUrlPattern, "") : pictureUrl;
      _this.item.pictureUrl = picturePath;
      _this.item.fullPictureUrl = pictureUrl;
    });
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$t("rewards.title") || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      const { fullPictureUrl } = this.item;
      let form = [
        {
          text: this.$t("rewards.form.name") || "Name*",
          value: "",
          placeholder: this.$t("rewards.form.name") || "Name*",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("rewards.form.image") || "Image*",
          placeholder: this.$t("rewards.form.image") || "Image*",
          allowedFormatsText: this.$t("rewards.form.allowedFormatsText") || "Allowed Formats",
          allowedFormats: this.$t("rewards.form.allowedFormats") || "jpg, png",
          showPreview: true,
          fileNames: [fullPictureUrl],
          multiple: false,
          fieldName: "FileUploadField",
          value: "",
          folderUpload: "Rewards",
          returnString: true,
          name: "pictureUrl",
          class: "col-12 col-sm-12 col-md-12",
          required: true,
          rules: ""
        },
        {
          text: this.$t("rewards.form.points") || "Points*",
          value: "",
          placeholder: this.$t("rewards.form.points") || "Points*",
          name: "points",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-6",
          rules: "required|numeric"
        },
        {
          text: this.$t("rewards.form.quantity") || "Quantity*",
          value: "",
          placeholder: this.$t("rewards.form.quantity") || "Quantity*",
          name: "quantity",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-6",
          rules: "required|numeric"
        }
      ];
      return form;
    }
  }
};
</script>
