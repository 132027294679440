<template>
  <list-data-iterator
    :icon="icon"
    :title="title"
    :show-actions="showActions"
    :card-component="cardComponent"
    :route-name="routeName"
    :namespace="namespace"
  ></list-data-iterator>
</template>
<script>
export default {
  name: "ProductsList",
  components: {
    ListDataIterator: () => import("@/components/core/iterators/ListDataIterator")
  },
  data() {
    return {
      routeName: "products",
      namespace: "products",
      showActions: true,
      cardComponent: "ProductCard",
      title: this.$t("products.title") || "Products"
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
