import View from "@/views/admin/Reward.vue";
import Index from "@/components/rewards/List.vue";
import Create from "@/components/rewards/Create.vue";
import Edit from "@/components/rewards/Edit.vue";
import Show from "@/components/rewards/Show.vue";
export default [
  {
    path: "rewards",
    name: "rewards",
    component: View,
    title: "Rewards",
    redirect: {
      name: "list-rewards"
    },
    children: [
      {
        path: "list",
        name: "list-rewards",
        meta: {
          auth: {
            roles: [0, 1],
            rolesKey: "role"
          },
          icon: "mdi-gift"
        },
        component: Index
      },
      {
        path: "create",
        name: "create-rewards",
        meta: {
          storeAction: "rewards/insertItem",
          namespace: "rewards",
          store: "rewards",
          title: "Create Rewards",
          icon: "mdi-gift"
        },
        component: Create
      },
      {
        path: "edit/:id",
        name: "edit-rewards",
        meta: {
          storeAction: "rewards/updateItem",
          namespace: "rewards",
          store: "rewards",
          title: "Edit rewards",
          icon: "mdi-gift"
        },
        component: Edit
      },
      {
        path: "show/:id",
        name: "show-rewards",
        meta: {
          auth: {
            roles: [0, 1],
            rolesKey: "role"
          },
          namespace: "rewards",
          store: "rewards",
          icon: "mdi-gift"
        },
        component: Show
      }
    ]
  }
];
