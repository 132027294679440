import View from "@/views/admin/Purchase.vue";
import Index from "@/components/purchases/List.vue";
import MyPurchasesList from "@/components/purchases/MyPurchasesList.vue";
export default [
  {
    path: "purchases",
    name: "purchases",
    component: View,
    title: "Purchases",
    redirect: {
      name: "list-purchases"
    },
    children: [
      {
        path: "list",
        name: "list-purchases",
        meta: {
          icon: "mdi-cart-outline"
        },
        component: Index
      },
      {
        path: "my-purchases",
        name: "my-purchases",
        meta: {
          auth: {
            roles: [0, 1],
            rolesKey: "role"
          },
          icon: "mdi-cart"
        },
        component: MyPurchasesList
      }
    ]
  }
];
