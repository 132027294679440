import RestApi from "@/api/RestApi";
export default class CompaniesApi extends RestApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "sales-companies/";
  }

  async getList(pagination) {
    let url = this.baseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async getCompanyCities(pagination) {
    let url = this.baseEndpoint + "cities/";
    let response = await this.getData(url, pagination);
    return response;
  }

  async getCompanyShops(pagination) {
    let url = this.baseEndpoint + "shops/";
    let response = await this.getData(url, pagination);
    return response;
  }
}
