<template>
  <v-row>
    <v-col>
      <router-view />
    </v-col>
  </v-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  }
};
</script>
