<template>
  <v-container fluid>
    <v-row justify="space-around" no-gutters>
      <v-col cols="12">
        <v-sheet color="grey lighten-5" class="pa-5" v-if="item">
          <v-row align="center" justify="start">
            <v-col cols="8" class="text-left">
              <div class="d-flex">
                <v-avatar size="100" v-if="icon">
                  <v-icon size="85" left>{{ icon }}</v-icon>
                </v-avatar>
                <div class="ma-2 my-auto">
                  <span
                    class="grey--text text--darken-2 mb-2 display-1 d-flex"
                    v-if="item.firstName && item.lastName"
                  >
                    {{ item.firstName }} {{ item.lastName }}</span
                  >
                  <h2 class="grey--text text--darken-1 my-2 title">
                    <span
                      >{{ item.address }} <span v-if="item.cityName">, {{ item.cityName }}</span
                      ><span>, {{ item.postalCode }}</span></span
                    >
                  </h2>
                  <h2 class="grey--text text--darken-1 my-2 title">
                    <span v-if="item.city"
                      >{{ item.city.name
                      }}<span v-if="item.shop">, {{ item?.shop?.address }}</span></span
                    >
                  </h2>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              <h3 class="grey--text text--darken-1 ma-2 subtitle">
                <v-list-item-subtitle class="my-1"
                  ><v-icon color="yellow darken-3" left>mdi-star</v-icon>
                  {{ $t("navigation.availablePoints") }} :
                  <span class="font-weight-bold">{{
                    formatNumber(item.availablePoints) || "/"
                  }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="my-1"
                  ><v-icon color="success" left>mdi-star</v-icon>
                  {{ $t("navigation.earnedPoints") }} :
                  <span class="font-weight-bold">{{ formatNumber(item.earnedPoints) || "/" }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="my-1"
                  ><v-icon color="info" left>mdi-star</v-icon>
                  {{ $t("navigation.reservedPoints") }} :
                  <span class="font-weight-bold">{{
                    formatNumber(item.reservedPoints) || "/"
                  }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="my-1"
                  ><v-icon color="error" left>mdi-star</v-icon> {{ $t("navigation.spentPoints") }} :
                  <span class="font-weight-bold">{{ formatNumber(item.spentPoints) || "/" }}</span>
                </v-list-item-subtitle>
              </h3>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab" background-color="teal accent-4" centered dark grow icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1">
            {{ $t("users.purchaseTab") }}
            <v-icon>mdi-cart-outline</v-icon>
          </v-tab>

          <v-tab href="#tab-2">
            {{ $t("users.salesTab") }}
            <v-icon>mdi-sale</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :value="'tab-1'">
            <list-data-iterator
              :show-actions="true"
              :icon="purchaseIcon"
              :card-component="purchaseCardComponent"
              :route-name="purchaseRouteName"
              :item-create="false"
              :show-toolbar="false"
              :namespace="purchaseNamespace"
            ></list-data-iterator>
          </v-tab-item>
          <v-tab-item :value="'tab-2'">
            <list-data-iterator
              :show-actions="true"
              :icon="saleIcon"
              :card-component="saleCardComponent"
              :route-name="saleRouteName"
              :item-create="false"
              :show-toolbar="false"
              :namespace="saleNamespace"
            ></list-data-iterator>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "UserShow",
  components: {
    ListDataIterator: () => import("@/components/core/iterators/ListDataIterator")
  },
  data() {
    return {
      tab: "tab-1",
      purchaseNamespace: "purchases",
      saleNamespace: "sales",
      purchaseRouteName: "purchases",
      saleRouteName: "sales",
      purchaseCardComponent: "PurchaseCard",
      saleCardComponent: "SaleCard",
      saleIcon: "mdi-sale",
      purchaseIcon: "mdi-cart-outline"
    };
  },
  created() {},
  async mounted() {
    const _this = this;
    _this.$store.commit("purchases/setPagination", {
      userId: this.id
    });
    _this.$store.commit("sales/setPagination", {
      userId: this.id
    });
    await _this.$store.dispatch("users/getItemById", _this.id);
  },

  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("purchases/setPagination", { userId: null });
    this.$store.dispatch("sales/setPagination", { userId: null });
    next();
  },
  computed: {
    ...mapGetters({
      item: "users/item"
    }),
    icon() {
      return this.$route.meta.icon || "";
    },
    id() {
      return this.$route.params.id || null;
    }
  }
};
</script>
