import moment from "moment";
export default {
  methods: {
    /**
     * @param {date} date {string} format
     * Format Date and Time
     */
    formatDateAndTime(date, format = "DD.MM.YYYY, HH:mm") {
      if (!date) return date;
      return moment(date).utc(date).local().format(format);
    },
    /**
     * @param {date} date {string} format
     * Format Date
     */
    formatDate(date, format = "DD.MM.YYYY") {
      if (!date) return date;
      return moment(date).format(format);
    },
    formatTime(time, withSeconds) {
      if (!time) return time;
      if (withSeconds) {
        return moment(time, "HH:mm:ss").format("HH:mm:ss");
      } else {
        return moment(time, "HH:mm").format("HH:mm");
      }
    },
    /**
     * @param {date} date
     * Humanize Date
     */
    humanizeDate(date) {
      if (!date) return date;
      let serverOffset = moment(date).utcOffset();
      let dateFix = new Date(moment(date).add(serverOffset, "minutes"));
      return moment(dateFix).fromNow();
    }
  }
};
