<template>
  <v-container fluid>
    <v-row justify="space-around" no-gutters>
      <v-col cols="12">
        <v-sheet color="grey lighten-5" class="pa-5" v-if="item">
          <v-row align="center" justify="start">
            <v-col cols="5" class="text-left">
              <div class="d-flex">
                <v-avatar size="200" v-if="item.pictureUrl">
                  <v-img cover height="200px" :src="item.pictureUrl"></v-img>
                </v-avatar>
                <div class="ma-2 my-auto">
                  <span class="grey--text text--darken-2 mb-2 display-1 d-flex">
                    {{ item.name }}</span
                  >
                  <h2 class="grey--text text--darken-1 my-2 title">
                    <span v-if="item.startDate"
                      >{{ formatDate(item.startDate)
                      }}<span v-if="item.endDate"> - {{ formatDate(item.endDate) }}</span></span
                    >
                  </h2>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              <v-list-item v-if="item.cities">
                <v-list-item-content>
                  <v-list-item-title
                    ><v-icon left>mdi-city</v-icon
                    ><span v-for="(city, index) in item.cities" v-bind:key="index"
                      >{{ city.name }} <span v-if="index + 1 < item.cities.length">, </span></span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="item.companies">
                <v-list-item-content>
                  <v-list-item-title
                    ><v-icon left>mdi-store</v-icon
                    ><span v-for="(company, index) in item.companies" v-bind:key="index"
                      >{{ company.name }}
                      <span v-if="index + 1 < item.companies.length">, </span></span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.shops">
                <v-list-item-content>
                  <template v-for="(shop, index) in item.shops">
                    <v-chip v-if="index < 5" class="my-2 mr-2" v-bind:key="index">
                      <span>{{ shop.address }}</span>
                    </v-chip>
                    <span
                      v-if="index === 5 && item.shops.length > 5"
                      v-bind:key="index"
                      class="grey--text text-caption"
                    >
                      (+{{ item.shops.length - 5 }})
                    </span>
                  </template>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="3">
              <v-list-item v-if="item.categories">
                <v-list-item-content>
                  <v-list-item-title
                    ><v-icon left>mdi-format-list-bulleted</v-icon
                    ><span v-for="(category, index) in item.categories" v-bind:key="index"
                      >{{ category.name }}
                      <span v-if="index + 1 < item.categories.length">, </span></span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="item.subCategories">
                <v-list-item-content>
                  <v-list-item-title
                    ><v-icon left>mdi-format-list-checks</v-icon
                    ><span v-for="(sub, index) in item.subCategories" v-bind:key="index"
                      >{{ sub.name }}
                      <span v-if="index + 1 < item.subCategories.length">, </span></span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.products">
                <v-list-item-content>
                  <template v-for="(product, index) in item.products"
                    ><span v-if="index < 5" v-bind:key="index">
                      <v-chip class="my-2 mr-2">
                        <span>{{ product.modelName }}</span>
                      </v-chip>
                      -
                      <v-chip color="yellow darken-3" small text-color="white" class="ma-1">
                        <v-icon small left> mdi-star </v-icon>
                        {{ product.points }}
                      </v-chip></span
                    >
                    <span
                      v-bind:key="index"
                      v-if="index === 5 && item.products.length > 5"
                      class="grey--text text-caption"
                      >(+{{ item.products.length - 5 }})
                    </span>
                  </template>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab" background-color="teal accent-4" centered dark grow icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1">
            {{ $t("campaigns.salesTab") }}
            <v-icon>mdi-sale</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :value="'tab-1'">
            <list-data-iterator
              :show-actions="true"
              :icon="saleIcon"
              :card-component="saleCardComponent"
              :route-name="saleRouteName"
              :item-create="false"
              :show-toolbar="false"
              :namespace="saleNamespace"
            ></list-data-iterator>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CampaignShow",
  components: {
    ListDataIterator: () => import("@/components/core/iterators/ListDataIterator")
  },
  data() {
    return {
      tab: "tab-1",
      saleNamespace: "sales",
      saleRouteName: "sales",
      saleCardComponent: "SaleCard",
      saleIcon: "mdi-sale"
    };
  },
  created() {},
  async mounted() {
    const _this = this;
    _this.$store.commit("sales/setPagination", {
      salesCampaignId: this.id
    });
    await _this.$store.dispatch("campaigns/getItem", _this.id);
  },

  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("sales/setPagination", { salesCampaignId: null });
    next();
  },
  computed: {
    ...mapGetters({
      item: "campaigns/item"
    }),
    icon() {
      return this.$route.meta.icon || "";
    },
    id() {
      return this.$route.params.id || null;
    }
  }
};
</script>
