import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  items: [],
  categories: [],
  subcategories: [],
  latest: [],
  item: {},
  loading: false,
  disabled: false,
  pagination: {
    search: "",
    skip: 0,
    page: 1,
    limit: 12
  },
  categoriesPagination: {
    search: "",
    skip: 0,
    page: 1,
    limit: 200
  },
  subcategoriesPagination: {
    search: "",
    categoriesIds: [],
    skip: 0,
    page: 1,
    limit: 200
  },
  productsPagination: {
    search: "",
    subCategoriesIds: [],
    skip: 0,
    page: 1,
    limit: 500
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
