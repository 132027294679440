import actions from "@/store/files/actions";
import getters from "@/store/files/getters";
import mutations from "@/store/files/mutations";

export default {
  namespaced: true,
  state: {
    loading: false,
    folderUpload: "NoSpecifiedFolderUpload"
  },
  actions,
  getters,
  mutations
};
