import View from "@/views/admin/News.vue";
import Index from "@/components/news/List.vue";
import Create from "@/components/news/Create.vue";
import Edit from "@/components/news/Edit.vue";
import Show from "@/components/news/Show.vue";
export default [
  {
    path: "news",
    name: "news",
    component: View,
    title: "News",
    redirect: {
      name: "list-news"
    },
    children: [
      {
        path: "list",
        name: "list-news",
        meta: {
          icon: "mdi-newspaper",
          auth: {
            roles: [0, 1],
            rolesKey: "role"
          }
        },
        component: Index
      },
      {
        path: "create",
        name: "create-news",
        meta: {
          storeAction: "news/insertItem",
          namespace: "news",
          store: "news",
          title: "Create News",
          icon: "mdi-newspaper"
        },
        component: Create
      },
      {
        path: "edit/:id",
        name: "edit-news",
        meta: {
          storeAction: "news/updateItem",
          namespace: "news",
          store: "news",
          title: "Edit news",
          icon: "mdi-newspaper"
        },
        component: Edit
      },
      {
        path: "show/:id",
        name: "show-news",
        meta: {
          auth: {
            roles: [0, 1],
            rolesKey: "role"
          },
          namespace: "news",
          store: "news",
          icon: "mdi-newspaper"
        },
        component: Show
      }
    ]
  }
];
