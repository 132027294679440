const items = (state) => state.items;
const latest = (state) => state.latest;
const item = (state) => state.item;
const loading = (state) => state.loading;
const disabled = (state) => state.disabled;
const error = (state) => state.error;
const pagination = (state) => state.pagination;

export default {
  items,
  item,
  loading,
  error,
  latest,
  disabled,
  pagination
};
