<template>
  <list-data-iterator
    :icon="icon"
    :title="title"
    :show-actions="showActions"
    :card-component="cardComponent"
    :route-name="routeName"
    :user-filter="userFilter"
    :item-create="itemCreate"
    :namespace="namespace"
  ></list-data-iterator>
</template>
<script>
export default {
  name: "UserList",
  components: {
    ListDataIterator: () => import("@/components/core/iterators/ListDataIterator")
  },
  data() {
    return {
      routeName: "users",
      namespace: "users",
      showActions: true,
      userFilter: true,
      cardComponent: "UserCard",
      itemCreate: false,
      title: this.$t("users.title") || "Users"
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
