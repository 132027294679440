import View from "@/views/admin/User.vue";
import Index from "@/components/users/List.vue";
import Show from "@/components/users/Show.vue";
import EditUser from "@/components/users/Edit.vue";
export default [
  {
    path: "users",
    name: "users",
    component: View,
    title: "Users",
    redirect: {
      name: "list-users"
    },
    children: [
      {
        path: "list",
        name: "list-users",
        meta: {
          icon: "mdi-account"
        },
        component: Index
      },
      {
        path: "show/:id",
        name: "show-users",
        meta: {
          icon: "mdi-account"
        },
        component: Show
      },
      {
        path: "profile",
        name: "edit-profile",
        meta: {
          auth: {
            roles: [0, 1],
            rolesKey: "role"
          },
          storeAction: "users/updateItem",
          storeAction2: "users/changePassword",
          namespace: "users",
          store: "users",
          title: "Edit users",
          icon: "mdi-account"
        },
        component: EditUser
      }
    ]
  }
];
