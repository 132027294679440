<template>
  <v-app id="app">
    <router-view />
    <snackbar></snackbar>
  </v-app>
</template>
<script>
export default {
  components: {
    Snackbar: () => import("@/components/core/Snackbar")
  },
  created() {}
};
</script>
