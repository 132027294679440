<template>
  <form-wizard
    :form-data="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :create-form-data="createFormData"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
export default {
  name: "RewardsCreate",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {
        name: "",
        pictureUrl: "",
        points: "",
        quantity: ""
      },
      createFormData: false
    };
  },
  created() {},
  mounted() {},
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$t("rewards.title") || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: this.$t("rewards.form.name") || "Name*",
          value: "",
          placeholder: this.$t("rewards.form.name") || "Name*",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("rewards.form.image") || "Image*",
          placeholder: this.$t("rewards.form.image") || "Image*",
          allowedFormatsText: this.$t("rewards.form.allowedFormatsText") || "Allowed Formats",
          allowedFormats: this.$t("rewards.form.allowedFormats") || "jpg, png",
          showPreview: true,
          fileNames: [],
          multiple: false,
          fieldName: "FileUploadField",
          value: "",
          folderUpload: "Rewards",
          returnString: true,
          name: "pictureUrl",
          class: "col-12 col-sm-12 col-md-12",
          required: true,
          rules: ""
        },
        {
          text: this.$t("rewards.form.points") || "Points*",
          value: "",
          placeholder: this.$t("rewards.form.points") || "Points*",
          name: "points",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-6",
          rules: "required|numeric"
        },
        {
          text: this.$t("rewards.form.quantity") || "Quantity*",
          value: "",
          placeholder: this.$t("rewards.form.quantity") || "Quantity*",
          name: "quantity",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-6",
          rules: "required|numeric"
        }
      ];
      return form;
    }
  }
};
</script>
