const items = (state) => state.items;
const myCampaigns = (state) => state.myCampaigns;
const activeCampaigns = (state) => state.activeCampaigns;
const item = (state) => state.item;
const loading = (state) => state.loading;
const disabled = (state) => state.disabled;
const pagination = (state) => state.pagination;

export default {
  items,
  item,
  myCampaigns,
  activeCampaigns,
  loading,
  disabled,
  pagination
};
