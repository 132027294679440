import RewardsApi from "@/api/rewards";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new RewardsApi();

const getItems = async ({ commit, state }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.getList(state.pagination);
    commit("setItemsData", { value: items, shouldReset: state.pagination.skip === 0 });
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

const getItem = async ({ commit }, itemId) => {
  try {
    commit("setLoading", true);
    const item = await restApi.getItem(itemId);
    commit("setItemData", item);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const insertItem = async ({ commit }, item) => {
  try {
    commit("setLoading", true);
    await restApi.insertItem(item);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const updateItem = async ({ commit }, item) => {
  try {
    commit("setLoading", true);
    await restApi.updateItem(item.id, item);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const deleteItem = async ({ commit }, item) => {
  try {
    commit("setLoading", true);
    await restApi.deleteItem(item);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

export default {
  getItems,
  getItem,
  setPagination,
  insertItem,
  updateItem,
  deleteItem
};
