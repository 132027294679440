"use strict";
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import i18n from "./i18n";

let config = {
  baseURL: process.env.VUE_APP_API_URL || "/api"
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    store.commit("options/setOptionsData", { loading: true }, { root: true });

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    if (
      typeof response.data.message !== "undefined" &&
      response.data.message != "" &&
      response.data.statusCode === 204
    ) {
      store.commit(
        "options/setOptionsData",
        { message: i18n.tc("app.saveSuccess"), snackbar: true, color: "success" },
        { root: true }
      );
    }
    store.commit("options/setOptionsData", { loading: false }, { root: true });
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      // if you ever get an unauthorized, logout the user
      Vue.router.push({ name: "logout" });
      // you can also redirect to /login if needed !
    }

    if (
      error.response.data?.responseException &&
      error.response.data.responseException?.exceptionMessage
    ) {
      store.commit(
        "options/setOptionsData",
        {
          message: Array.isArray(error.response.data?.responseException.exceptionMessage)
            ? error.response.data?.responseException.exceptionMessage[0].errorMessage
            : error.response.data?.responseException.exceptionMessage,
          snackbar: true,
          color: "red"
        },
        { root: true }
      );
    }
    store.commit("options/setOptionsData", { loading: false }, { root: true });
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
