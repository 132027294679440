<template>
  <div>
    <v-tabs v-model="tab" background-color="teal accent-4" centered dark grow icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        {{ $t("users.personalInfo") }}
        <v-icon>mdi-account</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        {{ $t("users.changePassword") }}
        <v-icon>mdi-lock</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <form-wizard
          v-if="item"
          :title="$t('users.personalInfo')"
          :form-data.sync="formData"
          :fetch-user-data="true"
          :item="item"
          :namespace="namespace"
          :store="store"
          :store-action="storeAction"
        ></form-wizard>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <form-wizard
          :create-form-data="createFormData"
          :title="$t('users.changePassword')"
          :form-data.sync="formData2"
          :item="item2"
          :namespace="namespace"
          :store="store"
          :store-action="storeAction2"
        ></form-wizard>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
export default {
  name: "UsersEdit",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {},
      item2: {},
      tab: "tab-1",
      createFormData: false
    };
  },
  created() {
    const _this = this;
    _this.$store.dispatch(_this.store + "/getItem").then(() => {
      const { firstName, lastName, address, phone, postalCode, cityName } =
        _this.$store.getters[_this.store + "/item"];
      _this.item = { firstName, lastName, address, phone, postalCode, cityName };
    });
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    storeAction2() {
      return this.$route.meta.storeAction2 || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$t("users.myAccount") || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: this.$t("users.form.firstName") || "First name*",
          value: "",
          placeholder: this.$t("users.form.firstName") || "First name*",
          name: "firstName",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("users.form.lastName") || "Last name*",
          value: "",
          placeholder: this.$t("users.form.lastName") || "Last name*",
          name: "lastName",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("users.form.city") || "City *",
          value: "",
          placeholder: this.$t("users.form.city") || "City*",
          name: "cityName",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("users.form.address") || "Address*",
          value: "",
          placeholder: this.$t("users.form.address") || "Address*",
          name: "address",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("users.form.postalCode") || "Postal code*",
          value: "",
          placeholder: this.$t("users.form.postalCode") || "Postal code*",
          name: "postalCode",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("users.form.phone") || "Phone*",
          value: "",
          placeholder: this.$t("users.form.phone") || "Phone*",
          name: "phone",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        }
      ];
      return form;
    },
    formData2() {
      let form = [
        {
          text: this.$t("users.form.oldPassword") || "Old Password*",
          value: "",
          placeholder: this.$t("users.form.oldPassword") || "Old Password*",
          name: "oldPassword",
          fieldName: "PasswordField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("users.form.newPassword") || "New password*",
          value: "",
          placeholder: this.$t("users.form.newPassword") || "New password*",
          name: "newPassword",
          fieldName: "PasswordField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required|min:6"
        },
        {
          text: this.$t("users.form.newPasswordConfirm") || "New password confirm*",
          value: "",
          placeholder: this.$t("users.form.newPasswordConfirm") || "New password confirm*",
          name: "newPasswordConfirm",
          fieldName: "PasswordField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required|min:6|confirmed:newPassword"
        }
      ];
      return form;
    }
  }
};
</script>
