import Vue from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import store from "@/store";
import router from "@/router";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import vuetify from "@/plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import i18n from "@/plugins/i18n";
import "./plugins/axios";
import "./plugins/auth";
import validationMixin from "@/mixins/validationMixin";
import date from "@/mixins/date";
import string from "@/mixins/string";
import url from "@/mixins/url";
import role from "@/mixins/role";
import number from "@/mixins/number";
Vue.config.productionTip = false;
Vue.mixin(validationMixin);
Vue.mixin(date);
Vue.mixin(string);
Vue.mixin(url);
Vue.mixin(role);
Vue.mixin(number);

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi"
});

const vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
});

window.Event = vue;
vue.$mount("#app");
