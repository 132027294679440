import Vue from "vue";
import VueRouter from "vue-router";
import News from "@/router/news";
import Products from "@/router/products";
import Campaigns from "@/router/campaigns";
import Rewards from "@/router/rewards";
import Users from "@/router/users";
import Sales from "@/router/sales";
import Purchases from "@/router/purchases";

Vue.use(VueRouter);

function loadView(view) {
  return () => import(/* webpackChunkName: "lazy-view-[request]" */ `../views/${view}.vue`);
}
function loadAdminView(view) {
  return () => import(/* webpackChunkName: "lazy-view-[request]" */ `../views/admin/${view}.vue`);
}
function loadSalesView(view) {
  return () => import(/* webpackChunkName: "lazy-view-[request]" */ `../views/sales/${view}.vue`);
}
function loadErrorView(view) {
  return () => import(/* webpackChunkName: "lazy-view-[request]" */ `../views/errors/${view}.vue`);
}

const routes = [
  {
    path: "/app",
    meta: {
      auth: {
        redirect: { name: "login" },
        notFoundRedirect: { name: "404" },
        forbiddenRedirect: { name: "403" },
        roles: 1,
        rolesKey: "role"
      }
    },
    redirect: { name: "dashboard" },
    component: loadView("Wrapper"),
    children: [
      {
        path: "dashboard-admin",
        name: "dashboard",
        meta: {
          icon: "mdi-view-dashboard-outline"
        },
        component: loadAdminView("Dashboard")
      },
      {
        path: "dashboard",
        name: "app-dashboard",
        meta: {
          auth: {
            roles: [0, 1],
            rolesKey: "role"
          },
          icon: "mdi-view-dashboard-outline"
        },
        component: loadSalesView("Dashboard")
      },
      ...News,
      ...Products,
      ...Campaigns,
      ...Rewards,
      ...Users,
      ...Sales,
      ...Purchases
    ]
  },
  {
    path: "/",
    name: "login",
    component: loadView("Login")
  },
  {
    path: "/confirm-registration/:id",
    name: "confirm-registration",
    component: loadView("ConfirmRegistration")
  },
  {
    path: "/reset-password/:id",
    name: "reset-password",
    component: loadView("ResetPassword")
  },
  {
    path: "/register",
    name: "register",
    component: loadView("Register")
  },
  {
    path: "/thank-you",
    name: "thankYou",
    component: loadView("ThankYou")
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: loadView("ForgotPassword")
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      auth: true
    },
    component: loadView("Logout")
  },
  {
    path: "/403",
    name: "403",
    component: loadErrorView("403")
  },
  {
    path: "/404",
    name: "404",
    component: loadErrorView("404")
  },
  {
    path: "*",
    redirect: {
      name: "404"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

Vue.router = router;
window.router = router;
export default router;
