<template>
  <form-wizard
    :form-data.sync="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
import { mapGetters } from "vuex";
import date from "@/mixins/date";
export default {
  mixins: [date],
  name: "CampaignEdit",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {
        name: "",
        pictureUrl: "",
        startDate: null,
        endDate: null,
        active: true,
        salesCompaniesIds: [],
        citiesIds: [],
        shopsIds: [],
        category: [],
        subCategoryId: [],
        productIds: [],
        products: []
      },
      createFormData: false
    };
  },
  async created() {
    const _this = this;

    await _this.$store.dispatch(_this.store + "/getItem", _this.$route.params.id).then(() => {
      _this.item = _this.$store.getters[_this.store + "/item"];

      const { pictureUrl } = _this.item;
      const baseUrlPattern = process.env.VUE_APP_BASE_IMAGE_URL;
      const picturePath = pictureUrl ? pictureUrl.replace(baseUrlPattern, "") : pictureUrl;
      _this.item.pictureUrl = picturePath;
      const pluck = (arr, key) => arr.map((i) => i[key]);
      _this.item.category = pluck(_this.item.categories, "id");
      _this.item.subCategoryId = pluck(_this.item.subCategories, "id");
      _this.item.productIds = _this.item.products.map(({ productId: id, ...rest }) => ({
        id,
        ...rest
      }));
      _this.item.salesCompaniesIds = pluck(_this.item.companies, "id");
      _this.item.citiesIds = pluck(_this.item.cities, "id");
      _this.item.shopsIds = pluck(_this.item.shops, "id");
      _this.item.startDate = this.formatDate(_this.item.startDate, "YYYY-MM-DD");
      _this.item.endDate = this.formatDate(_this.item.endDate, "YYYY-MM-DD");
      _this.item.fullPictureUrl = pictureUrl;
    });
    //load items
    await this.$store.dispatch("companies/getItems");
    this.$store.commit("companies/setPagination", {
      salesCompaniesIds: this.item.salesCompaniesIds
    });
    await this.$store.dispatch("companies/getCities");
    this.$store.commit("companies/setPagination", {
      citiesIds: this.item.citiesIds
    });
    await this.$store.dispatch("companies/getShops");

    await this.$store.dispatch("products/getCategories");
    this.$store.commit("products/setSubcategoriesPagination", {
      categoriesIds: this.item.category
    });
    this.$store.dispatch("products/getSubcategories");

    this.$store.commit("products/setProductsPagination", {
      subCategoriesIds: this.item.subCategoryId
    });
    this.$store.dispatch("products/getItems", { pagin: "productsPagination" });

    //events

    Event.$on("select-field-changed-salesCompaniesIds", (value) => {
      this.$store.commit("companies/setPagination", {
        salesCompaniesIds: value
      });
      this.$store.dispatch("companies/getCities");
    });
    Event.$on("select-field-changed-citiesIds", (value) => {
      this.$store.commit("companies/setPagination", {
        citiesIds: value
      });
      this.$store.dispatch("companies/getShops");
    });
    Event.$on("select-field-changed-category", (value) => {
      this.$store.commit("products/setSubcategoriesPagination", {
        categoriesIds: value
      });
      this.$store.dispatch("products/getSubcategories");
    });
    Event.$on("select-field-changed-subCategoryId", (value) => {
      this.$store.commit("products/setProductsPagination", {
        subCategoriesIds: value
      });
      this.$store.dispatch("products/getItems", { pagin: "productsPagination" });
    });
    Event.$on("select-field-changed-productIds", (value) => {
      const options = value.map(function (row) {
        return {
          productId: row.id,
          modelName: row.modelName,
          points: row.points || row.defaultPoints
        };
      });

      this.item.products = options;
    });
  },
  computed: {
    ...mapGetters({
      categories: "products/categories",
      subcategories: "products/subcategories",
      companies: "companies/items",
      cities: "companies/cities",
      shops: "companies/shops",
      products: "products/items"
    }),
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$t("campaigns.title") || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      const { fullPictureUrl } = this.item;
      let form = [
        {
          text: this.$t("campaigns.form.name") || "Name*",
          value: "",
          placeholder: this.$t("campaigns.form.name") || "Name*",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("campaigns.form.image") || "Image*",
          placeholder: this.$t("campaigns.form.image") || "Image*",
          allowedFormatsText: this.$t("campaigns.form.allowedFormatsText") || "Allowed Formats",
          allowedFormats: this.$t("campaigns.form.allowedFormats") || "jpg, png",
          showPreview: true,
          fileNames: [fullPictureUrl],
          multiple: false,
          fieldName: "FileUploadField",
          value: "",
          folderUpload: "SalesCampaigns",
          returnString: true,
          name: "pictureUrl",
          class: "col-12 col-sm-12 col-md-12",
          required: true,
          rules: ""
        },
        {
          text: this.$t("campaigns.form.startDate") || "Start date*",
          placeholder: this.$t("campaigns.form.startDate") || "Start date*",
          fieldName: "DateField",
          value: null,
          name: "startDate",
          minDate: "1950-01-01",
          dateFormat: "YYYY-MM-DD",
          readonly: true,
          fullDate: true,
          class: "col-12 col-sm-12 col-md-4",
          required: true,
          rules: ""
        },
        {
          text: this.$t("campaigns.form.endDate") || "End date*",
          placeholder: this.$t("campaigns.form.endDate") || "End date*",
          fieldName: "DateField",
          value: null,
          name: "endDate",
          minDate: "1950-01-01",
          dateFormat: "YYYY-MM-DD",
          fullDate: true,
          class: "col-12 col-sm-12 col-md-4",
          required: true,
          rules: ""
        },
        {
          text: this.$t("campaigns.form.active") || "Active",
          placeholder: this.$t("campaigns.form.active") || "Active",
          fieldName: "SwitchField",
          value: null,
          name: "active",
          class: "col-12 col-sm-12 col-md-4",
          required: true,
          rules: ""
        },
        {
          text: this.$t("campaigns.form.company") || "Kompanija*",
          value: [],
          placeholder: this.$t("campaigns.form.company") || "Kompanija*",
          name: "salesCompaniesIds",
          multiple: true,
          items: this.companies,
          disabled: false,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: ""
        },
        {
          text: this.$t("campaigns.form.city") || "Grad*",
          value: [],
          placeholder: this.$t("campaigns.form.city") || "Grad*",
          name: "citiesIds",
          multiple: true,
          items: this.cities,
          disabled: false,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: ""
        },
        {
          text: this.$t("campaigns.form.shops") || "Prodavnice*",
          value: "",
          placeholder: this.$t("campaigns.form.shops") || "Prodavnice*",
          name: "shopsIds",
          multiple: true,
          items: this.shops,
          itemText: "name",
          disabled: false,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: "required"
        },
        {
          text: this.$t("products.form.category") || "Category*",
          value: [],
          placeholder: this.$t("products.form.category") || "Category*",
          name: "category",
          multiple: true,
          items: this.categories,
          disabled: false,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: ""
        },
        {
          text: this.$t("products.form.subcategory") || "Subcategory*",
          value: [],
          placeholder: this.$t("products.form.subcategory") || "Subcategory*",
          name: "subCategoryId",
          multiple: true,
          disabled: false,
          items: this.subcategories,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: ""
        },
        {
          text: this.$t("products.form.products") || "Products",
          value: [],
          placeholder: this.$t("products.form.products") || "Products*",
          name: "productIds",
          multiple: true,
          disabled: false,
          items: this.products,
          itemText: "modelName",
          itemValue: "productId",
          fieldName: "SelectField",
          required: true,
          returnObject: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: "required"
        },
        {
          text: this.$t("products.form.productPoints") || "Product points*",
          value: "",
          placeholder: this.$t("products.form.productPoints") || "Product points*",
          name: "products",
          loadFrom: "productIds",
          fieldName: "ProductField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        }
      ];
      return form;
    }
  }
};
</script>
