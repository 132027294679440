import RestApi from "@/api/RestApi";
export default class RewardsApi extends RestApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "users/";
  }

  async getList(pagination) {
    let url = this.baseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async getItem() {
    let url = this.baseEndpoint + "profile";
    let response = await this.getData(url);
    return response;
  }

  async getItemById(id) {
    let url = this.baseEndpoint + id;
    let response = await this.getData(url);
    return response;
  }
  async updateItem(item) {
    let url = this.baseEndpoint + "profile";
    let response = await this.putData(url, item);
    return response;
  }

  async changePassword(item) {
    console.log(item);
    let url = this.baseEndpoint + "password/change";
    let response = await this.patchData(url, item);
    return response;
  }

  async resetPassword(item, code) {
    try {
      let url = this.baseEndpoint + code + "/password/reset";
      let response = await this.insertData(url, item);
      return response;
    } catch (error) {
      return error.response?.data;
    }
  }

  async forgotPassword(email) {
    try {
      let url = this.baseEndpoint + email + "/password/forgot";
      let response = await this.insertData(url);
      return response;
    } catch (error) {
      return error.response?.data;
    }
  }
  async confirmRegistration(id) {
    let url = this.domain + "registration/" + id + "/confirm";
    let response = await this.patchData(url);
    return response;
  }

  async resendConfirmation(email) {
    let url = this.domain + "registration/" + email + "/confirm/resend";
    let response = await this.insertData(url);
    return response;
  }

  async approve(id) {
    let url = this.domain + "registration/" + id + "/approve";
    let response = await this.patchData(url);
    return response;
  }
  async activate(id) {
    let url = this.baseEndpoint + id + "/activate";
    let response = await this.patchData(url);
    return response;
  }
  async deactivate(id) {
    let url = this.baseEndpoint + id + "/deactivate";
    let response = await this.patchData(url);
    return response;
  }
}
