const setLoading = (state, value) => {
  state.loading = value;
};
const setFolderUpload = (state, value) => {
  state.folderUpload = value;
};
export default {
  setLoading,
  setFolderUpload
};
