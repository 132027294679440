import FilesApi from "@/api/files";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new FilesApi();

const uploadFilePublic = async ({ commit }, { item, folder }) => {
  try {
    commit("setLoading", true);
    const { result } = await restApi.uploadFilePublic(item, folder);
    commit("setLoading", false);
    return result;
  } catch (error) {
    errorHandling.onError(error);
  }
};

export default {
  uploadFilePublic
};
