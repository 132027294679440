<template>
  <list-data-iterator
    :icon="icon"
    :title="title"
    :show-actions="showActions"
    :card-component="cardComponent"
    :route-name="routeName"
    :namespace="namespace"
    :store-action="storeAction"
  ></list-data-iterator>
</template>
<script>
export default {
  name: "NewsList",
  components: {
    ListDataIterator: () => import("@/components/core/iterators/ListDataIterator")
  },
  data() {
    return {
      routeName: "campaigns",
      namespace: "campaigns",
      showActions: true,
      cardComponent: "CampaignCard",
      title: this.$t("campaigns.title") || "Campaigns"
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      if (this.isAdmin()) {
        return "getItems";
      }
      return "getMyCampaigns";
    }
  },
  created() {}
};
</script>
