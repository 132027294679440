import PurchasesApi from "@/api/purchases";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new PurchasesApi();
import i18n from "@/plugins/i18n";

const getItems = async ({ commit, state }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.getList(state.pagination);
    commit("setItemsData", { value: items, shouldReset: state.pagination.skip === 0 });
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const getMyPurchases = async ({ commit, state }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.getMyPurchases(state.pagination);
    commit("setMyPurchasesData", { value: items, shouldReset: state.pagination.skip === 0 });
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

const exportToCsv = async ({ commit, state }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.exportToCsv(state.pagination);
    commit("setLoading", false);
    return items;
  } catch (error) {
    errorHandling.onError(error);
  }
};

const insertItem = async ({ commit }, id) => {
  try {
    commit("setLoading", true);
    await restApi.insertItem(id);
    commit(
      "options/setOptionsData",
      {
        message: i18n.tc("purchases.recordPurchase"),
        snackbar: true,
        color: "success"
      },
      { root: true }
    );
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const approve = async ({ commit }, id) => {
  try {
    commit("setLoading", true);
    await restApi.approve(id);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};
const cancel = async ({ commit }, id) => {
  try {
    commit("setLoading", true);
    await restApi.cancel(id);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const deliver = async ({ commit }, id) => {
  try {
    commit("setLoading", true);
    await restApi.deliver(id);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};
export default {
  getItems,
  getMyPurchases,
  exportToCsv,
  setPagination,
  insertItem,
  approve,
  cancel,
  deliver
};
