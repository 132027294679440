<template>
  <form-wizard
    :form-data.sync="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :create-form-data="createFormData"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ProductsCreate",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {
        modelName: "",
        defaultPoints: "",
        pictureUrl: "",
        category: "",
        subCategoryId: ""
      },
      createFormData: false
    };
  },
  created() {
    this.$store.dispatch("products/getCategories");
    this.$store.dispatch("products/getSubcategories");
    Event.$on("select-field-changed-category", (value) => {
      this.$store.commit("products/setSubcategoriesPagination", {
        categoriesIds: value
      });
      this.$store.dispatch("products/getSubcategories");
    });
  },
  computed: {
    ...mapGetters({
      categories: "products/categories",
      subcategories: "products/subcategories"
    }),
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$t("products.title") || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: this.$t("products.form.modelName") || "Model name*",
          value: "",
          placeholder: this.$t("products.form.modelName") || "Model name*",
          name: "modelName",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("products.form.points") || "Points*",
          value: "",
          placeholder: this.$t("products.form.points") || "Points*",
          name: "defaultPoints",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required|numeric"
        },
        {
          text: this.$t("products.form.category") || "Category*",
          value: "",
          placeholder: this.$t("products.form.category") || "Category*",
          name: "category",
          multiple: false,
          items: this.categories,
          disabled: false,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-6",
          rules: ""
        },
        {
          text: this.$t("products.form.subcategory") || "Subcategory*",
          value: "",
          placeholder: this.$t("products.form.subcategory") || "Subcategory*",
          name: "subCategoryId",
          multiple: false,
          disabled: false,
          items: this.subcategories,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-6",
          rules: "required"
        },
        {
          text: this.$t("products.form.image") || "Image*",
          placeholder: this.$t("products.form.image") || "Image*",
          allowedFormatsText: this.$t("products.form.allowedFormatsText") || "Allowed Formats",
          allowedFormats: this.$t("products.form.allowedFormats") || "jpg, png",
          showPreview: true,
          fileNames: [],
          multiple: false,
          fieldName: "FileUploadField",
          folderUpload: "Products",
          value: "",
          returnString: true,
          name: "pictureUrl",
          class: "col-12 col-sm-12 col-md-12",
          required: true,
          rules: ""
        }
      ];
      return form;
    }
  }
};
</script>
