const items = (state) => state.items;
const mySales = (state) => state.mySales;
const item = (state) => state.item;
const loading = (state) => state.loading;
const disabled = (state) => state.disabled;
const pagination = (state) => state.pagination;

export default {
  items,
  item,
  loading,
  disabled,
  mySales,
  pagination
};
