<template>
  <form-wizard
    :form-data="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :create-form-data="createFormData"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
import date from "@/mixins/date";
export default {
  name: "SalesCreate",
  mixins: [date],
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
      default: null
    },
    products: {
      type: [Array, Object],
      required: true,
      default: null
    }
  },
  data() {
    return {
      categories: [],
      subcategories: [],
      productsItems: [],
      allProducts: [],
      allSubcat: [],
      item: {
        salesCampaignId: "",
        category: "",
        subCategoryId: "",
        productId: "",
        date: this.formatDate(new Date(), "YYYY-MM-DD")
      },
      createFormData: false
    };
  },
  async created() {
    if (!this.id && !this.products) {
      return this.goBack();
    }
    this.item.salesCampaignId = this.id;
    const categories = [];
    const subcategories = [];
    const products = [];
    const uniqueArray = (a) =>
      [...new Set(a.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));
    this.products.forEach((element) => {
      categories.push(element.category);
      subcategories.push(element.subCategory);
      products.push(element);
    });
    this.categories = uniqueArray(categories);
    this.subcategories = uniqueArray(subcategories);
    this.allSubcat = uniqueArray(subcategories);
    this.productItems = uniqueArray(products);
    this.allProducts = uniqueArray(products);
    Event.$on("select-field-changed-category", (value) => {
      this.subcategories = this.allSubcat.filter((item) => item.categoryId == value);
    });
    Event.$on("select-field-changed-subCategoryId", (value) => {
      this.productItems = this.allProducts.filter((item) => {
        return item.subCategory.id == value;
      });
    });
  },
  computed: {
    disabledSubcategory() {
      return this.item.category ? false : true;
    },
    disabledProducts() {
      return this.item.subCategoryId ? false : true;
    },
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$t("sales.title") || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: this.$t("products.form.category") || "Category*",
          value: "",
          placeholder: this.$t("products.form.category") || "Category*",
          name: "category",
          multiple: false,
          items: this.categories,
          disabled: false,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-6",
          rules: ""
        },
        {
          text: this.$t("products.form.subcategory") || "Subcategory*",
          value: "",
          placeholder: this.$t("products.form.subcategory") || "Subcategory*",
          name: "subCategoryId",
          multiple: false,
          disabled: this.disabledSubcategory,
          items: this.subcategories,
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-6",
          rules: "required"
        },
        {
          text: this.$t("sales.form.products") || "Products",
          value: "",
          placeholder: this.$t("sales.form.products") || "Products*",
          name: "productId",
          multiple: false,
          disabled: this.disabledProducts,
          items: this.productItems,
          itemText: "modelName",
          fieldName: "SelectField",
          required: true,
          class: "col-12 col-sm-12 col-md-4",
          rules: "required"
        },
        {
          text: this.$t("sales.form.date") || "Date*",
          placeholder: this.$t("sales.form.date") || "Date*",
          fieldName: "DateField",
          value: null,
          name: "date",
          minDate: "1950-01-01",
          dateFormat: "YYYY-MM-DD",
          fullDate: true,
          class: "col-12 col-sm-12 col-md-6",
          required: true,
          rules: "required"
        }
      ];
      return form;
    }
  }
};
</script>
