import Vue from "vue";
const setItemsData = (state, { value, shouldReset }) => {
  if (shouldReset) {
    state.items = [];
  }
  value.result.forEach((item) => {
    const index = state.items.findIndex((a) => a.id === item.id);
    if (index === -1) {
      state.items.push(item);
    }
  });
  state.disabled = value.result.length < state.pagination.limit;
};

const setLatestItemsData = (state, value) => {
  // state.latest = value.data ? value.data : value;
  state.latest = value.result;
};

const setItemData = (state, value) => {
  state.item = value.result;
};

const setLoading = (state, value) => {
  state.loading = value;
};

const setPagination = (state, value) => {
  Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

export default {
  setItemsData,
  setItemData,
  setLatestItemsData,
  setPagination,
  setLoading
};
