import SalesApi from "@/api/sales";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new SalesApi();
import i18n from "@/plugins/i18n";

const getItems = async ({ commit, state }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.getList(state.pagination);
    commit("setItemsData", { value: items, shouldReset: state.pagination.skip === 0 });
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const getMySales = async ({ commit, state }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.getMySales(state.pagination);
    commit("setMySalesData", { value: items, shouldReset: state.pagination.skip === 0 });
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

const exportToCsv = async ({ commit, state }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.exportToCsv(state.pagination);
    commit("setLoading", false);
    return items;
  } catch (error) {
    errorHandling.onError(error);
  }
};

const insertItem = async ({ commit }, item) => {
  try {
    commit("setLoading", true);
    await restApi.insertItem(item);
    commit("setLoading", false);
    commit(
      "options/setOptionsData",
      {
        message: i18n.tc("sales.recordSale"),
        snackbar: true,
        color: "success"
      },
      { root: true }
    );
  } catch (error) {
    errorHandling.onError(error);
  }
};

const approve = async ({ commit }, id) => {
  try {
    commit("setLoading", true);
    await restApi.approve(id);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};
const reject = async ({ commit }, id) => {
  try {
    commit("setLoading", true);
    await restApi.reject(id);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

export default {
  getItems,
  getMySales,
  exportToCsv,
  setPagination,
  insertItem,
  approve,
  reject
};
