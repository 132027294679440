export default {
  methods: {
    toFixedWithoutRounding(num, fixed) {
      const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
      return num.toString().match(re)[0];
    },

    formatNumber(value, decimals) {
      const digits = decimals || 1;
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
          return value >= item.value;
        });
      return item
        ? this.toFixedWithoutRounding(value / item.value, digits).replace(rx, "$1") + item.symbol
        : "0";
    }
  }
};
