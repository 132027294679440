import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  items: [],
  latest: [],
  item: {},
  disabled: false,
  loading: false,
  pagination: {
    search: "",
    skip: 0,
    page: 1,
    limit: 12
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
