import Vue from "vue";

import auth from "@websanova/vue-auth/src/v2.js";
import driverHttpAxios from "@websanova/vue-auth/src/drivers/http/axios.1.x.js";
// import driverHttpVueResource from '@websanova/vue-auth/src/drivers/http/vue-resource.1.x.js';
import driverRouterVueRouter from "@websanova/vue-auth/src/drivers/router/vue-router.2.x.js";
Vue.use(auth, {
  plugins: {
    http: Vue.axios, // Axios
    // http: Vue.http, // Vue Resource
    router: Vue.router
  },
  drivers: {
    auth: {
      request: function (req, token) {
        this.drivers.http.setHeaders.call(this, req, {
          Authorization: "Bearer " + token
        });
        // Set auth_refresh_token on every request
        this.drivers.http.setHeaders.call(this, req, {
          Refresh: this.token(this.options.refreshTokenKey)
        });
      },

      response: function (res) {
        if (res.data?.result?.accessToken && res.data?.result?.refreshToken) {
          this.token(this.options.accessToken, res.data.result.accessToken);
          this.token(this.options.refreshTokenKey, res.data.result.refreshToken);
        }
      }
    },
    http: driverHttpAxios, // Axios
    router: driverRouterVueRouter
  },
  options: {
    refreshData: {
      url: "login/refresh",
      method: "POST",
      enabled: true,
      interval: 60
    },
    fetchData: {
      enabled: true,
      url: "users/info",
      method: "GET"
    },
    parseUserData: function (data) {
      return data.result;
    },
    loginData: {
      url: "login",
      method: "POST",
      redirect: "dashboard",
      fetchUser: false
    },
    registerData: {
      url: "registration",
      method: "POST",
      redirect: "thankYou",
      fetchUser: false,
      autoLogin: false
    },
    rememberKey: "user",
    rolesKey: "role",
    tokenDefaultKey: "accessToken",
    refreshTokenKey: "refreshToken",
    stores: ["storage"],
    authRedirect: { name: "login" },
    forbiddenRedirect: { name: "403" }
  }
});
