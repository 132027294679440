import Vue from "vue";
const setItemsData = (state, value) => {
  state.items = value.result;
};

const setCitiesData = (state, value) => {
  state.cities = value.result;
};

const setShopsData = (state, value) => {
  state.shops = value.result;
};

const setLoading = (state, value) => {
  state.loading = value;
};

const setPagination = (state, value) => {
  Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

const setPaginationValue = (state, data) => {
  if (!data.value) {
    delete(state.pagination[data.name]);
  } else {
    state.pagination[data.name] = data.value;
  }
};

export default {
  setItemsData,
  setCitiesData,
  setShopsData,
  setPaginationValue,
  setPagination,
  setLoading
};
