<template>
  <reward-card :item="item" :icon="icon" :image-height="imageHeight" :namespace="namespace" />
</template>
<script>
export default {
  name: "RewardsShow",
  data() {
    return {
      item: {},
      imageHeight: "400px"
    };
  },
  components: { RewardCard: () => import("@/components/core/cards/RewardCard") },
  created() {},
  async mounted() {
    const _this = this;
    await _this.$store.dispatch(_this.store + "/getItem", _this.$route.params.id).then(() => {
      _this.item = _this.$store.getters[_this.store + "/item"];
    });
  },
  computed: {
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$t("rewards.title") || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    icon() {
      return this.$route.meta.icon || "";
    }
  }
};
</script>
