import View from "@/views/admin/Product.vue";
import Index from "@/components/products/List.vue";
import Create from "@/components/products/Create.vue";
import Edit from "@/components/products/Edit.vue";
import Show from "@/components/products/Show.vue";
export default [
  {
    path: "products",
    name: "products",
    component: View,
    title: "Products",
    redirect: {
      name: "list-products"
    },
    children: [
      {
        path: "list",
        name: "list-products",
        meta: {
          icon: "mdi-television"
        },
        component: Index
      },
      {
        path: "create",
        name: "create-products",
        meta: {
          storeAction: "products/insertItem",
          namespace: "products",
          store: "products",
          title: "Create Products",
          icon: "mdi-television"
        },
        component: Create
      },
      {
        path: "edit/:id",
        name: "edit-products",
        meta: {
          storeAction: "products/updateItem",
          namespace: "products",
          store: "products",
          title: "Edit products",
          icon: "mdi-television"
        },
        component: Edit
      },
      {
        path: "show/:id",
        name: "show-products",
        meta: {
          namespace: "products",
          store: "products",
          icon: "mdi-television"
        },
        component: Show
      }
    ]
  }
];
