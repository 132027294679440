import CompaniesApi from "@/api/companies";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new CompaniesApi();

const getItems = async ({ commit, state }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.getList(state.pagination);
    commit("setItemsData", items);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const getCities = async ({ commit, state }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.getCompanyCities(state.pagination);
    commit("setCitiesData", items);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

const getShops = async ({ commit, state }) => {
  try {
    commit("setLoading", true);
    const items = await restApi.getCompanyShops(state.pagination);
    commit("setShopsData", items);
    commit("setLoading", false);
  } catch (error) {
    errorHandling.onError(error);
  }
};

export default {
  getItems,
  getCities,
  getShops
};
