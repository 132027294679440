import RestApi from "@/api/RestApi";
export default class SalesApi extends RestApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "sales/";
  }

  async getList(pagination) {
    let url = this.baseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async getMySales(pagination) {
    let url = this.baseEndpoint + "personal";
    let response = await this.getData(url, pagination);
    return response;
  }

  async insertItem(item) {
    let url = this.baseEndpoint;
    let response = await this.insertData(url, item);
    return response;
  }
  async approve(id) {
    let url = this.baseEndpoint + id + "/approve";
    let response = await this.patchData(url);
    return response;
  }
  async reject(id) {
    let url = this.baseEndpoint + id + "/reject";
    let response = await this.patchData(url);
    return response;
  }
  async exportToCsv(pagination) {
    let url = this.baseEndpoint + "report";
    let response = await this.getData(url, pagination);
    return response;
  }
}
