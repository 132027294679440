<template>
  <list-data-iterator
    :icon="icon"
    :title="title"
    :show-actions="showActions"
    :card-component="cardComponent"
    :route-name="routeName"
    :sale-filter="saleFilter"
    :sale-export="saleExport"
    :item-create="itemCreate"
    :namespace="namespace"
  ></list-data-iterator>
</template>
<script>
export default {
  name: "SaleList",
  components: {
    ListDataIterator: () => import("@/components/core/iterators/ListDataIterator")
  },
  data() {
    return {
      routeName: "sales",
      namespace: "sales",
      showActions: true,
      saleFilter: true,
      saleExport: true,
      cardComponent: "SaleCard",
      itemCreate: false,
      title: this.$t("sales.title") || "Sales"
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
