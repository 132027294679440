<template>
  <form-wizard
    :form-data="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :create-form-data="createFormData"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
export default {
  name: "NewsCreate",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {
        title: "",
        pictureUrl: "",
        text: ""
      },
      createFormData: false
    };
  },
  created() {},
  mounted() {},
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$t("news.title") || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: this.$t("news.form.title") || "Title*",
          value: "",
          placeholder: this.$t("news.form.title") || "Title*",
          name: "title",
          fieldName: "StandardTextField",
          required: true,
          class: "col-12 col-sm-12 col-md-12",
          rules: "required"
        },
        {
          text: this.$t("news.form.image") || "Image*",
          placeholder: this.$t("news.form.image") || "Image*",
          allowedFormatsText: this.$t("news.form.allowedFormatsText") || "Allowed Formats",
          allowedFormats: this.$t("news.form.allowedFormats") || "jpg, png",
          showPreview: true,
          fileNames: [],
          multiple: false,
          fieldName: "FileUploadField",
          value: "",
          folderUpload: "News",
          returnString: true,
          name: "pictureUrl",
          class: "col-12 col-sm-12 col-md-12",
          required: true,
          rules: ""
        },
        {
          text: this.$t("news.form.text") || "Description*",
          placeholder: this.$t("news.form.text") || "Description*",
          fieldName: "RichTextAreaField",
          value: "",
          name: "text",
          class: "col-12 col-sm-12 col-md-12",
          required: true,
          rules: "required"
        }
      ];
      return form;
    }
  }
};
</script>
